

.green {
  color: green;
  font-weight: 700;
}

.red {
  color: #fd3995;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: '';
}

.b5 {
  margin-left: -15px;
}
/* .b5 {
  background-color: transparent;
}

.b5.active, .b5.focus{
  outline: none;
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
  outline: none !important;
  box-shadow: none !important;   
}

.b5::-moz-focus-inner {
  border: 0;
} */

.b4 {
  color: inherit;
}
.b3 {
  border: 0;
  background-color: transparent;
}

.b1 {
  width: -webkit-fill-available;
}

.b2 {
  text-align: left;
}

.nav-menu li.active > button {
  color: white;
  background-color: rgba(255, 255, 255, 0.04);
  box-shadow: inset 3px 0 0 #886ab5;
  font-weight: 400;
}

.nav-menu li button {
  display: flex;
  align-items: center;
  outline: 0;
  padding: 0.8125rem 2rem;
  font-size: 0.875rem;
  color: #bdafd1;
  font-weight: 400;
  text-decoration: none;
  position: relative;
}

.fc-dayGrid-view .fc-body .fc-row {
  min-height: 10em;
}

.bt1 {
  margin-right: 10px;
}

.el1 {
  justify-content: flex-end;
  margin: auto;
}
.dop1 {
  padding-right: 17px;
  display: block;
}

.toast-top-right {
  top: 12px;
  right: 12px;
  position: fixed;
}

.btn-mini {
  padding: 2px 2px;
  height: 17px;
  width: 17px;
  font-size: 11px;
  line-height: 2px;  
}

/* .demo {
  height: 17px;
} */

i {
  font-family: fontawesome !important;
  }

.table1{
  position: relative; 
  clear: both; 
  height: 447px;
}

.table2{
  overflow: hidden; 
  position: relative; 
  border: 0px; 
  width: 100%;  
}

.table3{
  box-sizing: content-box; 
  width: 2363px; 
  padding-right: 17px; 
}

.table4{
  margin-left: 0px;
  width: 2363px;
}

.table5{
  position: relative; 
  overflow: auto; 
  width: 100%; 
  max-height: 400px;
}

.table6{
  width: 2348px;
}

.th1{
  width: 50px; 
  padding-top: 0px; 
  padding-bottom: 0px; 
  border-top-width: 0px; 
  border-bottom-width: 0px; 
  height: 0px;
}

.th2{
  height: 0px; 
  overflow: hidden;  
}


  .logo1{
    text-decoration: none;
  }

  .nav-menu li.active:not(.open) > a:before {
    content: '\f413';
    font-family: 'nextgen-icons';
    position: absolute;
    top: calc(50% - 5px);
    right: 11px;
    font-size: 7px;
    height: 7px;
    width: 7px;
    color: #24b3a4;
    background: #24b3a4;  
    border-radius: 50%;  
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }



